import React from 'react'
import 'react-under-construction/build/css/index.css'
import MainComponent from './MainComponent'
import './App.css'


const App = () => (
  <MainComponent></MainComponent>
  
);

export default App;